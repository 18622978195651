export const APP_BROADCAST_VALUES = {
  AUTHENTICATE_BY_SSO: "AUTHENTICATE_BY_SSO",
  CLICK_LOG_OUT: "CLICK_LOG_OUT",
  HANDLE_LOGIN_RESPONSE: "HANDLE_LOGIN_RESPONSE",
  INIT_TRIALED_SUBSCRIPTION: "INIT_TRIALED_SUBSCRIPTION",
  REFETCH_ENRICHED_CONTACTS: "REFETCH_ENRICHED_CONTACTS",
  UPDATE_ACCOUNT_STATE: "UPDATE_ACCOUNT_STATE",
  UPDATE_MASTER_ACCOUNT: "UPDATE_MASTER_ACCOUNT",
  UPDATE_CURRENT_USER_ACCESS_TOKEN: "UPDATE_CURRENT_USER_ACCESS_TOKEN",
  LOGOUT_ZOOM: "LOGOUT_ZOOM",
} as const;

export const SCHEDULING_ASSISTANT_BROADCAST_VALUES = {
  SET_EVENTS: "SET_EVENTS",
  GET_EVENTS: "GET_EVENTS",
  UPDATE_RENDER_ID: "UPDATE_RENDER_ID",
  ADD_EVENTS: "ADD_EVENTS",
} as const;

export const BACKEND_BROADCAST_VALUES = {
  FETCH_OUTLOOK_CATEGORIES: "FETCH_OUTLOOK_CATEGORIES",
  FETCH_OUTSTANDING_SLOTS: "FETCH_OUTSTANDING_SLOTS",
  PATCH_OUTSTANDING_SLOT: "PATCH_OUTSTANDING_SLOT",
  SHOW_SETTINGS_CONFIRMATION: "SHOW_SETTINGS_CONFIRMATION",
  UPDATE_PERSONAL_LINK_TRIPS: "UPDATE_PERSONAL_LINK_TRIPS",
  RELOAD_GOOGLE_PLACES_SCRIPT: "RELOAD_GOOGLE_PLACES_SCRIPT",
  FETCH_EVENT_ATTACHMENTS: "FETCH_EVENT_ATTACHMENTS",
  FETCH_EVENTS_FOR_CALENDAR: "FETCH_EVENTS_FOR_CALENDAR",
  STORE_USER_INFORMATION: "STORE_USER_INFORMATION",
  INITIAL_CONNECTED_USERS_SYNC: "INITIAL_CONNECTED_USERS_SYNC",
  GET_DISTRO_LISTS: "GET_DISTRO_LISTS",
  GET_BILLING_INFO: "GET_BILLING_INFO",
  UPDATE_SOCIAL_LINKS: "UPDATE_SOCIAL_LINKS",
  UPDATE_EVENT_RESPONSE: "UPDATE_EVENT_RESPONSE",
} as const;

export const BROADCAST_VALUES = {
  CLOSE_LAYOUT_MODAL: "CLOSE_LAYOUT_MODAL",
  DISABLE_HOT_KEYS: "DISABLE_HOT_KEYS",
  DISPLAY_RECURRING_COLOR_MODAL: "DISPLAY_RECURRING_COLOR_MODAL",
  DISPLAY_RECURRING_CATEGORIES_MODAL: "DISPLAY_RECURRING_CATEGORIES_MODAL",
  ENABLE_HOT_KEYS: "ENABLE_HOT_KEYS",
  MARK_EVENT_AS_FREE_BUSY: "MARK_EVENT_AS_FREE_BUSY",
  /** Logging in as an exec when a personal user already exists. */
  OPEN_EXECUTIVE_AUTH_ERROR_MODAL: "OPEN_EXECUTIVE_AUTH_ERROR_MODAL",
  /** Logging in as a personal user when an exec already exists. */
  OPEN_PERSONAL_AUTH_ERROR_MODAL: "OPEN_PERSONAL_AUTH_ERROR_MODAL",
  OPEN_LOGIN_TO_NEW_ACCOUNTS: "OPEN_LOGIN_TO_NEW_ACCOUNTS",
  UPDATE_INSTANCES: "UPDATE_INSTANCES",
  UPDATE_EVENT: "UPDATE_EVENT",
  PARSE_ICS_FILE: "PARSE_ICS_FILE",
  PARSE_MULTIPLE_EVENTS_RESPONSE: "PARSE_MULTIPLE_EVENTS_RESPONSE",
  OPEN_ACCOUNTS_DROPDOWN: "OPEN_ACCOUNTS_DROPDOWN",
  CLOSE_ACCOUNTS_DROPDOWN: "CLOSE_ACCOUNTS_DROPDOWN",
  TOGGLE_CALENDAR_FROM_CALENDAR_LIST: "TOGGLE_CALENDAR_FROM_CALENDAR_LIST",
  ON_CONNECT_WEBHOOKS: "ON_CONNECT_WEBHOOKS",
  ON_DISCONNECT_WEBHOOKS: "ON_DISCONNECT_WEBHOOKS",
  PULL_DATA_FROM_DATE: "PULL_DATA_FROM_DATE",
  REFETCH_MEET_WITH_EVENTS: "REFETCH_MEET_WITH_EVENTS",
} as const;

export const MAIN_CALENDAR_BROADCAST_VALUES = {
  RESET_MAIN_CALENDAR_CACHE: "RESET_MAIN_CALENDAR_CACHE",
  ADD_MIMIC_DELETE_EVENT: "ADD_MIMIC_DELETE_EVENT",
  WIPE_OUT_EVENTS_AND_ADD_EVENTS: "WIPE_OUT_EVENTS_AND_ADD_EVENTS",
  REMOVE_EVENT_FROM_WEEKLY_CALENDAR: "REMOVE_EVENT_FROM_WEEKLY_CALENDAR",
  INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR: "INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR",
  REMOVE_UNSELECTED_TIME_ZONE: "REMOVE_UNSELECTED_TIME_ZONE",
  ADD_OUTLOOK_PREVIEW_EVENTS: "ADD_OUTLOOK_PREVIEW_EVENTS",
  REMOVE_OUTLOOK_PREVIEW_EVENTS: "REMOVE_OUTLOOK_PREVIEW_EVENTS",
  SET_HOVER_POPUP_EVENT: "SET_HOVER_POPUP_EVENT",
  REPLACE_OUTLOOK_PREVIEW_EVENT: "REPLACE_OUTLOOK_PREVIEW_EVENT",
  ADD_TEMPORARY_SEARCHED_EVENTS: "ADD_TEMPORARY_SEARCHED_EVENTS",
  UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR:
    "UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR",
  DETERMINE_CALENDAR_VIEW_CHANGE: "DETERMINE_CALENDAR_VIEW_CHANGE",
  UPDATE_CALENDAR_VIEW: "UPDATE_CALENDAR_VIEW",
  REMOVE_ALL_EVENTS: "REMOVE_ALL_EVENTS",
  SHOW_FREE_TIMES_FOR_USER: "SHOW_FREE_TIMES_FOR_USER",
  UPDATE_EVENTS_HOLD_DETAILS: "UPDATE_EVENTS_HOLD_DETAILS",
  REMOVE_MULTIPLE_EVENTS: "REMOVE_MULTIPLE_EVENTS",
  BLUR_WEEKLY_CALENDAR: "BLUR_WEEKLY_CALENDAR",
  UPDATE_EVENT_INTO_WEEKLY_CALENDAR: "UPDATE_EVENT_INTO_WEEKLY_CALENDAR",
  ADD_EVENT_INTO_WEEKLY_CALENDAR: "ADD_EVENT_INTO_WEEKLY_CALENDAR",
  UPDATE_LAST_UPDATED_AT_FOR_DELETED_USER_EVENT_IDS: "UPDATE_LAST_UPDATED_AT_FOR_DELETED_USER_EVENT_IDS",
  REMOUNT_CALENDAR_WITH_DELAY: "REMOUNT_CALENDAR_WITH_DELAY",
  ON_SELECT_TIME_SLOT: "ON_SELECT_TIME_SLOT",
} as const;

export const AVAILABILITY_BROADCAST_VALUES = {
  USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE: "USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE",
  APPLY_EVENT_TEMPLATE_TO_SLOTS: "APPLY_EVENT_TEMPLATE_TO_SLOTS",
  CHECK_FOR_BLOCKING_CALENDARS: "CHECK_FOR_BLOCKING_CALENDARS",
  SET_ALL_DAY_SLOTS_WARNING: "SET_ALL_DAY_SLOTS_WARNING",
  SET_GENERIC_SLOTS_WARNING: "SET_GENERIC_SLOTS_WARNING",
  REMOVE_SLOTS_BEFORE_AFTER_CONFLICT_WARNING:
    "REMOVE_SLOTS_BEFORE_AFTER_CONFLICT_WARNING",
  REUSE_SLOTS_NOTIFICATION: "REUSE_SLOTS_NOTIFICATION",
  SET_SLOTS_STATE_AND_PROPS: "SET_SLOTS_STATE_AND_PROPS",
  ON_CLICK_GROUP_VOTE_EDIT: "ON_CLICK_GROUP_VOTE_EDIT",
  PASTE_TEXT_INTO_LOCATION: "PASTE_TEXT_INTO_LOCATION",
  PASTE_TEXT_INTO_DESCRIPTION: "PASTE_TEXT_INTO_DESCRIPTION",
  UPDATE_SLOTS_TYPE: "UPDATE_SLOTS_TYPE",
} as const;

export const RECURRENCE_BROADCAST_VALUES = {
  GET_ORIGINAL_EVENT: "GET_ORIGINAL_EVENT",
} as const;

export const LAYOUT_BROADCAST_VALUES = {
  TOGGLE_PROVIDE_FEEDBACK: "TOGGLE_PROVIDE_FEEDBACK",
  GROUP_VOTE_PREVIEW: "GROUP_VOTE_PREVIEW",
  OPEN_ONBOARDING_MODAL: "OPEN_ONBOARDING_MODAL",
  OPEN_ACCOUNTS: "OPEN_ACCOUNTS", // shows account dropdown
  MAIN_CALENDAR_SELECT_TOOLBAR: "MAIN_CALENDAR_SELECT_TOOLBAR",
  REROUTE_TO_HOME: "REROUTE_TO_HOME",
  TOGGLE_ON_SLOTS: "TOGGLE_ON_SLOTS",
  TOGGLE_ON_GROUP_POLLS: "TOGGLE_ON_GROUP_POLLS",
  REMOUNT_CALENDAR: "REMOUNT_CALENDAR",
} as const;

export const ROUTE_BROADCAST_VALUES = {
  ROUTE_ON_AUTH: "ROUTE_ON_AUTH",
  GO_TO_DESKTOP_LOGIN: "GO_TO_DESKTOP_LOGIN",
  GO_TO_NEW_USER_SSO_LOGIN: "GO_TO_NEW_USER_SSO_LOGIN",
} as const;

export const FETCH_BROADCAST_VALUES = {
  FETCH_PREVIEW_OUTLOOK_EVENTS: "FETCH_PREVIEW_OUTLOOK_EVENTS",
  FETCH_EVENTS_FOR_CURRENT_WINDOW: "FETCH_EVENTS_FOR_CURRENT_WINDOW",
  FETCH_INITIAL_CALENDAR_AND_EVENTS: "FETCH_INITIAL_CALENDAR_AND_EVENTS",
  FETCH_OUTLOOK_USER_EVENTS_FOR_WINDOW: "FETCH_OUTLOOK_USER_EVENTS_FOR_WINDOW",
} as const;

export const UPDATE_SETTINGS_BROADCAST_VALUES = {
  UPDATE_ACCOUNT_NAME: "UPDATE_ACCOUNT_NAME",
  UPDATE_ACCOUNT_LOCAL_TIME_ZONE: "UPDATE_ACCOUNT_LOCAL_TIME_ZONE",
  UPDATE_DEFAULT_CONFERENCING: "UPDATE_DEFAULT_CONFERENCING",
  UPDATE_DEFAULT_GUEST_PERMISSIONS: "UPDATE_DEFAULT_GUEST_PERMISSIONS",
  UPDATE_SETTINGS_PROPERTY: "UPDATE_SETTINGS_PROPERTY",
} as const;

export const MAGIC_LINK_BROADCAST_VALUES = {
  CREATE_MAGIC_LINK: "CREATE_MAGIC_LINK",
  SET_MAGIC_LINK_AFTER_CREATE: "SET_MAGIC_LINK_AFTER_CREATE",
  TOGGLE_CREATE_MAGIC_LINK_SPINNER: "TOGGLE_CREATE_MAGIC_LINK_SPINNER",
} as const;

export const EXPANDED_VIEW_BROADCAST_VALUES = {
  SET_FULL_OUTLOOK_EVENT: "SET_FULL_OUTLOOK_EVENT",
  EDIT_EXPANDED_EVENT: "EDIT_EXPANDED_EVENT",
  DUPLICATE_FROM_EVENT: "DUPLICATE_FROM_EVENT",
  ADD_GOOGLE_RSVP_COMMENT: "ADD_GOOGLE_RSVP_COMMENT",
  TURN_EVENT_INTO_TEMPLATE: "TURN_EVENT_INTO_TEMPLATE",
  TENTATIVE_AND_PROPOSE_TIME: "TENTATIVE_AND_PROPOSE_TIME",
  DECLINE_AND_PROPOSE_TIME: "DECLINE_AND_PROPOSE_TIME",
} as const;

export const EVENT_FORM_BROADCAST_VALUES = {
  ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM:
    "ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM",
  FETCH_AVAILABLE_ROOMS: "FETCH_AVAILABLE_ROOMS",
} as const;

export const LOGIN_BROADCAST_VALUES = {
  UPDATE_SHOW_SSO_BUTTON: "UPDATE_SHOW_SSO_BUTTON",
} as const;

export const SETTINGS_BROADCAST_VALUES = {
  SET_SETTINGS_PAGE: "SET_SETTINGS_PAGE",
} as const;

export const REMINDER_EMAIL_BROADCAST_VALUES = {
  RESTORE_BODY_DEFAULT: "RESTORE_BODY_DEFAULT",
  RESTORE_TITLE_DEFAULT: "RESTORE_TITLE_DEFAULT",
} as const;

export const HOLDS_BROADCAST_VALUES = {
  CREATE_LINKABLE_HOLDS: "CREATE_LINKABLE_HOLDS",
  CREATE_MIMIC_EVENTS: "CREATE_MIMIC_EVENTS",
  REMOVE_MIMIC_EVENTS: "REMOVE_MIMIC_EVENTS",
} as const;

export const MENU_BAR_BROADCAST_VALUES = {
  CHECK_MENU_BAR_COLOR: "CHECK_MENU_BAR_COLOR",
  MENU_BAR_SYNC_OTHER_CALENDARS: "MENU_BAR_SYNC_OTHER_CALENDARS",
  MENU_BAR_REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT: "MENU_BAR_REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT",
} as const;
